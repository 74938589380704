@import (once) "antd/dist/antd.less";
@import (once) "./less/Common.less";

.clicker {
  cursor: pointer !important;
}

.high-light-span{
  color:var(--c-keena-orange);
  font-weight: 600;
}

:focus {
  outline: none;
}

a {
  text-decoration: none !important;
}
