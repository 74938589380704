
html,
body,
body > #react-dom{
width: 100%;
height: 100%;
}

.ant-layout{
  height: 100%;
}
